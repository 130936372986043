/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ag-theme-quartz {
  padding: 15px;
//    --ag-foreground-color: rgb(14, 76, 134);
//    --ag-background-color: rgb(248, 252, 255);
    --ag-header-foreground-color: #181D1F;
    --ag-header-background-color: #eaeaea;
//    --ag-odd-row-background-color: rgb(208, 232, 255);
//    --ag-header-column-resize-handle-color: rgb(172, 229, 255);
//    --ag-wrapper-border-radius: 3px
}

button, select {
    padding: 3px 7px;
    border: solid 1px;
    border-radius: 3px;
    cursor: pointer;
}

.primary_btn {
    color: #fff;
    border-color: #0072a3;
    background-color: #0072a3;
}
.primary_btn:active {
    background-color: #008fcc;
    height: 8px;
}

.delete_btn {
    color: #fff;
    border-color: #db2100;
    background-color: #c21d00;
}
.delete_btn:active {
    background-color: #ff3c1a;
}

.cdk-overlay-container {
  z-index: 1200;
}
